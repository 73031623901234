import { GetCarePlanQuery, Goal, GoalsQuery } from '__generated__/graphql';
import { endOfDay, parse, parseISO, startOfDay } from 'date-fns';
import { useMemo } from 'react';

import { parseHealthieTime } from './utils';

type TransformedGoal = {
  id: string;
  name: string;
  created_at: string;
};

const DEFAULT_FORMAT = 'yyyy-MM-dd';

export const useTransformedAndSortedGoals = (inputData: GoalsQuery | undefined): TransformedGoal[] => {
  return useMemo(() => {
    const transformedGoals: TransformedGoal[] = [];

    if (inputData?.goals) {
      inputData.goals.forEach((goal) => {
        if (goal.name?.trim().includes('Personal') && goal.subgoals) {
          goal.subgoals.forEach((subgoal) => {
            subgoal.goal_histories.forEach((history) => {
              if (history.created_at) {
                transformedGoals.push({
                  id: history.id,
                  name: subgoal.name || '',
                  created_at: parseHealthieTime(history.created_at),
                });
              }
            });
          });
        }
      });
    }

    transformedGoals.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

    return transformedGoals;
  }, [inputData]);
};

export const useGetLatestCompletedPersonalGoal = (inputData: GetCarePlanQuery | undefined): string => {
  return useMemo(() => {
    const dates: string[] = [];
    let formattedDate = '';
    if (inputData?.currentUser?.active_care_plan?.goals) {
      inputData?.currentUser?.active_care_plan?.goals.forEach((goal) => {
        if (goal.name?.trim() === 'Complete Personal Goals' && goal.subgoals) {
          goal.subgoals.forEach((subgoal) => {
            subgoal.goal_histories.forEach((history) => {
              if (history && history.completed_on) {
                dates.push(history.completed_on);
              }
            });
          });
        }
      });
    }

    const sortedDateStrings = dates.sort((a, b) => b.localeCompare(a));
    if (sortedDateStrings.length > 0) {
      const [year, month, day] = sortedDateStrings[0].split('-');
      formattedDate = `${month}/${day}/${year}`;
    }

    return formattedDate;
  }, [inputData]);
};

export const useFilteredGoals = (goals: TransformedGoal[], startDate: string, endDate: string): TransformedGoal[] => {
  return useMemo(() => {
    const filteredGoals: TransformedGoal[] = goals.filter((goal) => {
      const start = startOfDay(parse(startDate, DEFAULT_FORMAT, new Date()));
      const end = endOfDay(parse(endDate, DEFAULT_FORMAT, new Date()));
      const goalDate = parseISO(goal.created_at as string);
      return goalDate >= start && goalDate <= end;
    });

    return filteredGoals;
  }, [goals, startDate, endDate]);
};

export const useGetNotesGoal = (inputData: GetCarePlanQuery | undefined): Goal | undefined => {
  return useMemo(() => {
    const notesGoal = inputData?.currentUser?.active_care_plan?.goals.find(
      (goal) => goal.name && goal.name.toLowerCase().includes('notes'),
    );
    return notesGoal;
  }, [inputData]);
};
