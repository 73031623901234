import { Entry } from '__generated__/graphql';
import { Point } from 'chart.js';
import { format, parse } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import meanBy from 'lodash/meanBy';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { parseHealthieDate } from 'utils/helpers';

import { getDates } from '../activity/timeUtils';
import { HeartRate, HeartRateMeasurement, Measurements } from './types';

export const groupMeasurements = (
  entries: Entry[],
  startDate: string,
  endDate: string,
): Measurements & HeartRateMeasurement => {
  const allDates = getDates(startDate, endDate);
  const entriesWithDates = entries.filter((entry) => !isNil(entry) && !isNil(entry.created_at));

  const filteredEntries = entriesWithDates.filter((item) => {
    if (item.created_at) {
      const createdDate = format(parseHealthieDate(item.created_at), 'yyyy-MM-dd');
      return startDate <= createdDate && createdDate <= endDate;
    }
  });

  // Do not fill with zeros
  if (isEmpty(filteredEntries)) {
    return {
      systolicData: [],
      diastolicData: [],
      heartRateData: [],
    };
  }

  const heartRateData = filteredEntries.map(
    (item) => item.subentries?.find((item) => item?.category?.toLowerCase().includes('heart rate'))?.metric_stat,
  );

  const systolicData = filteredEntries.map((item) => {
    const record = item?.subentries?.find((item) => item?.category?.toLowerCase().includes('systolic'));
    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      x: parseHealthieDate(item.created_at!).valueOf(),
      y: record?.metric_stat || 0,
    };
  });

  const diastolicData = filteredEntries.map((item) => {
    const record = item?.subentries?.find((item) => item?.category?.toLowerCase().includes('diastolic'));
    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      x: parseHealthieDate(item.created_at!).valueOf(),
      y: record?.metric_stat || 0,
    };
  });

  // Fill with zeros
  allDates.map((singleDate) => {
    const entryExist = systolicData.find((singleEntry) => format(new Date(singleEntry.x), 'yyyy-MM-dd') === singleDate);
    if (!entryExist) {
      systolicData.push({
        x: parse(singleDate, 'yyyy-MM-dd', new Date()).valueOf(),
        y: 0,
      });

      diastolicData.push({
        x: parse(singleDate, 'yyyy-MM-dd', new Date()).valueOf(),
        y: 0,
      });
    }
  });

  return {
    systolicData: sortBy(systolicData, 'x'),
    diastolicData: sortBy(diastolicData, 'x'),
    heartRateData: heartRateData.filter((item): item is HeartRate => Boolean(item)),
  };
};

export const useGetAverage = (diastolicData: Point[], systolicData: Point[]): string => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressure' });
  if (isEmpty(diastolicData) && isEmpty(systolicData)) return t('notAvailable');

  return `${Math.round(
    meanBy(
      diastolicData.filter((item) => item.y !== 0),
      'y',
    ),
  )}/${Math.round(
    meanBy(
      systolicData.filter((item) => item.y !== 0),
      'y',
    ),
  )}`;
};
