import styled from 'styled-components';

import { Typography } from '../Typography/Typography';

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-shrink: 0;
`;

export const QuestionDesc = styled(Typography.P)`
  margin: -8px 0 8px;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin: 4px 0;

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  @media (max-width: 830px) {
    gap: 16px 0;
    & > * {
      flex-basis: calc(100% / 6);
    }
  }

  @media (max-width: 319px) {
    gap: 16px 0;
    & > * {
      flex-basis: calc(100% / 4);
    }
  }
`;
