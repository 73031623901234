import { useField } from 'formik';
import React from 'react';
import { onEnterKey } from 'utils/helpers';

import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './Radio.styles';

export interface RadioProps {
  label?: string;
  name: string;
  value?: string;
  type?: Styled.RadioButtonType;
}

export const Radio: React.FC<RadioProps> = ({ label, name, value, type = 'horizontal' }) => {
  const [field, , helpers] = useField(name);
  const checked = field.value === value;
  const handleChange = () => {
    helpers.setValue(value);
  };
  return (
    <Styled.RadioContainer
      onClick={handleChange}
      onKeyDown={(e) => onEnterKey(e, handleChange)}
      checked={checked}
      tabIndex={0}
      role="radio"
      aria-checked={checked}
      aria-labelledby={`${name}-${value}-label`}
      type={type}
    >
      {type === 'circular' ? (
        <>
          <Styled.RadioInput type="radio" name={name} value={value} checked={checked} onChange={handleChange} />
          <Styled.CircularRadioLabel htmlFor={name}>{label}</Styled.CircularRadioLabel>
        </>
      ) : (
        <>
          <Typography color={checked && type === 'horizontal' ? 'other.white' : 'black.800'} aria-label={label}>
            {label}
          </Typography>
          <Styled.RadioInput type="radio" name={name} value={value} checked={checked} onChange={handleChange} />
          <Styled.RadioLabel htmlFor={name}>
            <Styled.OutlierCircle />
            <span />
          </Styled.RadioLabel>
        </>
      )}
    </Styled.RadioContainer>
  );
};
