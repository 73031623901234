import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const Content = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`;

export const Title = styled(Typography)`
  margin: 0px 4rem;
  text-align: center;

  @media (max-width: 320px) {
    margin: 0px 2.5rem;
  }
`;
