import { gql } from '__generated__';

export const CREATE_NOTE = gql(`
  mutation createNote($content: String!, $category: String!) {
    createEntry(input: { type: "NoteEntry", reflection: $content, category: $category}) {
        entry {
          id
          created_at
          updated_at
          type
          content: reflection
        }
        messages {
            field
            message
        }
    }
  }
`);

export const UPDATE_NOTE = gql(`
  mutation updateNote($id: ID, $content: String!) {
      updateEntry(input: { id: $id, reflection: $content }) {
          entry {
            id
            created_at
            updated_at
            type
            content: reflection
          }
          messages {
              field
              message
          }
      }
  }
`);

export const DELETE_NOTE = gql(`
  mutation deleteNote($id: ID) {
    deleteEntry(input: { id: $id }) {
      entry {
        id
      }
      messages {
        field
        message
      }
    }
  }
`);
