import styled, { css } from 'styled-components';

export type RadioButtonType = 'vertical' | 'horizontal' | 'circular';

export const RadioInput = styled.input`
  visibility: hidden;
  display: none;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.color.black[800]};
  margin-right: 10px;
  background-color: ${({ theme }) => theme.color.other.white};
  cursor: pointer;

  ${RadioInput}:checked + & {
    border-color: ${({ theme }) => theme.color.blue[500]};
  }

  ${RadioInput}:checked + & > span {
    background-color: ${({ theme }) => theme.color.blue[500]};
  }
`;

export const CircularRadioLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.other.backgroundPhotoLightGrey};
  min-width: 48px;
  min-height: 48px;
  font-size: 20px;
  aspect-ratio: 1;
  cursor: pointer;
  text-align: center;
  word-break: break-word;

  ${RadioInput}:checked + & {
    background-color: ${({ theme }) => theme.color.blue[500]};
    color: ${({ theme }) => theme.color.other.white};
  }
`;

export const OutlierCircle = styled.span`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: auto;
  ${RadioInput}:checked + & > span {
    background-color: ${({ theme }) => theme.color.blue[500]};
  }
`;

export const RadioContainer = styled.div<{ checked: boolean; type: RadioButtonType }>`
  display: flex;

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  ${({ theme, checked, type }) => {
    if (type === 'horizontal') {
      return css`
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 18px 16px;
        border: 1px solid ${theme.color.black[50]};
        background-color: ${checked ? theme.color.blue[700] : theme.color.other.white};
        border-radius: 8px;
      `;
    } else if (type === 'vertical') {
      return css`
        align-items: center;
        justify-content: space-between;
        flex-direction: column-reverse;
        gap: 4px;

        ${RadioLabel} {
          margin: 0;
        }
      `;
    } else if (type === 'circular') {
      return css`
        flex-direction: column;
        align-items: center;

        ${RadioLabel} {
          margin: 0;
        }
      `;
    }
  }}
`;
