import styled from 'styled-components';

import { ArticleHeader } from 'components/ui/ArticleHeader/ArticleHeader';

export const NotesHeader = styled(ArticleHeader)`
  margin-bottom: 16px;
`;

export const NotesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  flex: 1;

  h2 {
    color: ${({ theme }) => theme.color.blue[700]};
    font-size: 18px;
    font-weight: 500;
  }

  p {
    color: ${({ theme }) => theme.color.black[800]};
    font-size: 16px;
    line-height: 1.5;
    white-space: normal;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledTextArea = styled.textarea<{ $isMaxLength?: boolean }>`
  display: flex;
  flex-grow: 0.7;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: ${({ theme, $isMaxLength }) => ($isMaxLength ? `1px solid ${theme.color.other.red}` : 'none')};
  background: transparent;
  color: ${({ theme }) => theme.color.black[600]};
  font-size: 16px;
  resize: none;
  font-family: inherit;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.black[400]};
  }

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const NoteLabel = styled.span<{ $isMaxLength?: boolean }>`
  font-size: 12px;
  color: ${({ theme, $isMaxLength }) => ($isMaxLength ? theme.color.other.red : theme.color.black[400])};
  align-self: flex-start;
  margin-top: -12px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`;
