import { ReactComponent as EditIcon } from 'assets/vectors/fi-rr-pencil.svg';
import { format } from 'date-fns';
import { groupBy, isEmpty, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { parseHealthieDate } from 'utils/helpers';

import { Icon } from 'components/ui/Icon/Icon';
import { Typography } from 'components/ui/Typography/Typography';

import { Note } from '../useNote';
import {
  Box,
  DateContainer,
  EditContainer,
  EntriesContainer,
  GroupContainer,
  NoteContainer,
  NoteContent,
  NotesCount,
} from './NotesList.styles';

type NotesListProps = {
  notes: Note[];
  goalId?: string;
  notesCount?: number | null;
};

export const NotesList: React.FC<NotesListProps> = ({ notes, goalId, notesCount }) => {
  const navigate = useNavigate();
  const sortedGroupedNotes = useMemo(() => {
    const entriesWithDates = notes.filter((note) => !isEmpty(note.createdAt));
    const groupedData = groupBy(entriesWithDates, (el) => format(parseHealthieDate(el.createdAt), 'yyyy-MM-dd'));
    return sortBy(groupedData, (group) => {
      return entriesWithDates.indexOf(group[0]);
    });
  }, [notes]);

  return (
    <>
      <NotesCount size={22} weight={500} color="black.800">
        Notes {notesCount ? `(${notesCount})` : ''}
      </NotesCount>
      <GroupContainer>
        {sortedGroupedNotes.map((group) => {
          return (
            <div key={group[0].createdAt}>
              <DateContainer>
                <Typography size={16} weight={500} color="black.800">
                  {format(parseHealthieDate(group[0].createdAt), 'EEE, MMM d, yyyy')}
                </Typography>
              </DateContainer>
              <EntriesContainer>
                {group.map((note) => (
                  <Box
                    key={note.id}
                    onClick={() => navigate(paths.note.replace(':noteId', note.id).replace(':goalId', goalId ?? ''))}
                  >
                    <NoteContainer>
                      <Typography size={10} color="black.800" lineHeight={12}>
                        {format(
                          !isEmpty(note.updatedAt)
                            ? parseHealthieDate(note.updatedAt)
                            : parseHealthieDate(note.createdAt),
                          'h:mm aaa',
                        )}
                      </Typography>
                      <NoteContent variant="Body1" color="black.600">
                        {note.content}
                      </NoteContent>
                    </NoteContainer>
                    <EditContainer>
                      <Icon element={EditIcon} size={20} color="blue.700" />
                    </EditContainer>
                  </Box>
                ))}
              </EntriesContainer>
            </div>
          );
        })}
      </GroupContainer>
    </>
  );
};
