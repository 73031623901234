/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const paths = {
  homePage: '/',
  scheduleAppointmentWithSpecialist: '/schedule-appointment/:specialistType/:appointmentTypeId',
  scheduleAppointment: '/schedule-appointment',
  rescheduleAppointment: '/appointments/:appointmentId/slots',
  appointmentActionConfirmationMeetYourCoach: '/meet-your-coach/:appointmentId/:actionType',
  appointmentActionConfirmation: '/appointments/:appointmentId/:actionType',
  singleAppointment: '/appointments/:appointmentId',
  appointments: '/appointments',
  welcomePage: '/welcome',
  logInPage: '/log-in',
  logoutPage: '/logout',
  mailingAddress: '/mailing-address',
  settings: {
    main: '/settings',
    personalInfo: 'personal-info',
    accountSettings: 'account-settings',
    consents: 'consents',
    faq: 'faq',
    getHelp: 'help',
    devices: 'devices',
    notifications: 'notifications',
  },
  setupFirsAppointment: '/setup-first-appointment',
  setupPassword: '/set-password',
  chat: '/chat',
  consents: '/consents',
  consentsAccepted: '/consents-accepted',
  completeYourlessons: '/complete-your-lessons/:goalId',
  lesson: '/lesson/:goalId/:subgoalId/:lessonId',
  fitbitSetup: '/fitbit/setup',
  fitbitSync: '/fitbit/sync',
  recordYourWeight: '/record-your-weight/:goalId',
  recordYourWeightCompleted: '/record-your-weight-completed',
  recordYourMedicines: '/record-your-medicines/:goalId',
  recordYourMedicinesCompleted: '/record-your-medicines-completed',
  bloodPressureManualFirstTimeSlides: '/record-your-blood-pressure/:goalId/manual/slides/first-time',
  bloodPressureManual: '/record-your-blood-pressure/:goalId/manual/slides',
  recordYourBloodPressureSync: '/record-your-blood-pressure/:goalId/sync',
  recordYourBloodPressure: '/record-your-blood-pressure/:goalId',

  recordYourMedications: '/record-your-medications/:goalId',
  recordYourMedicationsCompleted: '/record-your-medications-completed',
  completeYourLessons: '/complete-your-lessons/:goalId',
  keepMoving: '/keep-moving/:goalId',
  completeYourAssesments: '/complete-your-assessments/:goalId',
  completeYourExerciseProgram: '/complete-your-exercise-program/:goalId',
  completeYourPersonalGoals: '/complete-your-personal-goals/:goalId',
  questionnaires: '/questionnaires',
  questionnaireById: '/questionnaires/:questionnaireId',
  questionnaireComplete: '/questionnaire-complete',
  todaysNote: '/add-notes/:goalId',
  note: '/note/:noteId/:goalId',
  allNotes: '/notes/:goalId',
  /*********************************************************/
  // Reset password
  forgotPassword: '/forgot-password',
  get emailSent() {
    return `${this.forgotPassword}/email-sent`;
  },
  get chooseNewPassword() {
    return `/set-new-password`;
  },
  get resetSuccess() {
    return `${this.forgotPassword}/reset-complete`;
  },
  get resetError() {
    return `${this.forgotPassword}/error`;
  },
  /*********************************************************/
  // Progress
  progress: '/progress',
  get weeklyReport() {
    return `${this.progress}/weekly-report`;
  },
  get carePlan() {
    return `${this.progress}/care-plan`;
  },
  get bloodPressure() {
    return `${this.progress}/blood-pressure`;
  },
  get activity() {
    return `${this.progress}/activity`;
  },
  get activityReadings() {
    return `${this.progress}/activity/readings`;
  },
  get bloodPressureReadings() {
    return `${this.bloodPressure}/readings`;
  },
  get weight() {
    return `${this.progress}/weight`;
  },
  get medications() {
    return `${this.progress}/medications`;
  },
  get personalGoals() {
    return `${this.progress}/personal-goals`;
  },

  /*********************************************************/
};
