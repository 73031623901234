import { ReactComponent as TrashIcon } from 'assets/vectors/trash-outline.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';

import { ButtonContainer } from '../Note.styles';
import * as Styled from './DeleteNote.styles';

interface DeleteNoteProps {
  onKeep: () => void;
  onDelete: () => void;
}

export const DeleteNote: React.FC<DeleteNoteProps> = ({ onKeep, onDelete }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.notes.deleteNote' });

  return (
    <BaseLayout
      footer={null}
      headerProps={{
        title: t('header'),
        variant: 'back',
        onBackClick: onKeep,
      }}
    >
      <Styled.Content>
        <Styled.Title variant="Headline1" color="black.800">
          {t('title')}
        </Styled.Title>
        <Icon element={TrashIcon} size={80} />
      </Styled.Content>
      <ButtonContainer>
        <Button variant="primary" size="md" onClick={onDelete}>
          {t('delete')}
        </Button>
        <Button variant="secondary" size="md" onClick={onKeep}>
          {t('keep')}
        </Button>
      </ButtonContainer>
    </BaseLayout>
  );
};

export default DeleteNote;
