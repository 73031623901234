import { Goal } from '__generated__/graphql';
import { ReactComponent as Assesment } from 'assets/vectors/ff-ri-survey.svg';
import { ReactComponent as EditSquare } from 'assets/vectors/fi-rr-edit-square.svg';
import { ReactComponent as Exercise } from 'assets/vectors/fi-rr-exercise.svg';
import { ReactComponent as Personal } from 'assets/vectors/fi-rr-flag.svg';
import { ReactComponent as HeartRate } from 'assets/vectors/fi-rr-heart rate.svg';
import { ReactComponent as Lessons } from 'assets/vectors/fi-rr-lesson.svg';
import { ReactComponent as Medication } from 'assets/vectors/fi-rr-medication.svg';
import { ReactComponent as PhoneIconComp } from 'assets/vectors/fi-rr-phone.svg';
import { ReactComponent as Print } from 'assets/vectors/fi-rr-print.svg';
import { ReactComponent as Steps } from 'assets/vectors/fi-rr-steps.svg';
import { ReactComponent as Weight } from 'assets/vectors/fi-rr-weight.svg';
import { paths } from 'router/paths';
import { Maybe } from 'utils/types';

export type GoalType =
  | 'pressure'
  | 'lessons'
  | 'moving'
  | 'medications'
  | 'weight'
  | 'assessments'
  | 'exercise'
  | 'personal'
  | 'certificate'
  | 'summary'
  | 'check'
  | 'notes';

export const GoalsMapping: Record<
  GoalType,
  { path: string; icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; redirectOnCompletion: boolean }
> = {
  pressure: { icon: HeartRate, path: paths.recordYourBloodPressureSync, redirectOnCompletion: true },
  lessons: { icon: Lessons, path: paths.completeYourLessons, redirectOnCompletion: true },
  moving: { icon: Steps, path: paths.keepMoving, redirectOnCompletion: true },
  medications: { icon: Medication, path: paths.recordYourMedications, redirectOnCompletion: false },
  weight: { icon: Weight, path: paths.recordYourWeight, redirectOnCompletion: false },
  assessments: { icon: Assesment, path: paths.questionnaires, redirectOnCompletion: false },
  exercise: { icon: Exercise, path: paths.completeYourExerciseProgram, redirectOnCompletion: true },
  certificate: { icon: Print, path: '', redirectOnCompletion: false },
  summary: { icon: Print, path: '', redirectOnCompletion: false },
  personal: { icon: Personal, path: paths.completeYourPersonalGoals, redirectOnCompletion: true },
  check: {
    icon: PhoneIconComp,
    path: paths.scheduleAppointmentWithSpecialist.replace(':specialistType', 'coach'),
    redirectOnCompletion: false,
  },
  notes: { icon: EditSquare, path: paths.todaysNote, redirectOnCompletion: true },
};

export const isGoalCompleted = (goal: Maybe<Goal>): boolean =>
  goal?.is_completed_for_date ? goal.is_completed_for_date : false;

export const getCompletionPercentage = (goal: Maybe<Goal> | undefined): number => {
  if (!goal) return 0;

  const subgoals = goal.subgoals ?? [];
  const totalSubgoals = subgoals.length;
  const completedSubgoals = subgoals.filter(isGoalCompleted).length;
  const subgoalsPercentage = totalSubgoals ? (completedSubgoals / totalSubgoals) * 100 : 0;
  const isGoalComplete = goal.is_completed_for_date;
  const goalPercentage = isGoalComplete ? 100 : 0;
  return isGoalComplete ? goalPercentage : subgoalsPercentage;
};

export const getCompletionNumericalRange = (goal: Maybe<Goal> | undefined): string => {
  if (!goal) return '0';
  const subgoals = goal.subgoals ?? [];
  const totalSubgoals = subgoals.length;
  const completedSubgoals = subgoals.filter(isGoalCompleted).length;
  if (totalSubgoals) {
    return `${completedSubgoals}/${totalSubgoals}`;
  } else {
    return isGoalCompleted(goal) ? '1/1' : '0/1';
  }
};

export const findIncompleteSubgoal = (
  goal: Maybe<Goal> | undefined,
): Maybe<{ goalId?: string; subgoalId?: string }> => {
  if (!goal || !goal.subgoals || goal.subgoals.length === 0) {
    // if goal has no subgoals, return goalId and no subgoalId
    return { goalId: goal?.id ?? '' };
  }

  let incompleteSubgoalCount = 0;
  let incompleteSubgoalId: string | undefined;

  for (const subgoal of goal.subgoals) {
    // check if subgoal is completed
    const completed = subgoal?.is_completed_for_date;
    if (!completed) {
      incompleteSubgoalCount++;
      incompleteSubgoalId = subgoal?.id;
      if (incompleteSubgoalCount > 1) {
        // if there are multiple incomplete subgoals, return only the subgoalId
        return { subgoalId: incompleteSubgoalId };
      }
    }
  }

  if (incompleteSubgoalCount === 1) {
    // if there's only one incomplete subgoal, return both goalId and subgoalId
    return { goalId: goal.id, subgoalId: incompleteSubgoalId };
  }

  // if all subgoals are completed or there are no subgoals, return null subgoalId
  return { goalId: goal.id };
};

export const isGoalNameInMapping = (goalName: Maybe<string>): boolean => {
  const goalNameWords = goalName?.toLowerCase()?.split(' ') || [];
  const isMatching = goalNameWords.some((word) => {
    return Object.keys(GoalsMapping).some((key) => {
      return word === key;
    });
  });
  return isMatching;
};

export const percentageOfWeeklyGoalsCompleted = (goals: Maybe<Goal>[] | undefined): number => {
  if (!goals || goals.length === 0) return 0;
  const weeklyGoals = goals.filter(
    (goal) => goal?.repeat === 'Weekly' && !goal.name?.toLowerCase().includes('personal'),
  );
  const totalWeeklyGoals = weeklyGoals.length;
  const completedWeeklyGoals = weeklyGoals.filter((goal) => goal?.is_completed_for_date).length;
  return completedWeeklyGoals / totalWeeklyGoals;
};
