import { ReactComponent as EditSquare } from 'assets/vectors/fi-rr-edit-square.svg';
import { ReactComponent as EditPencil } from 'assets/vectors/fi-rr-pencil.svg';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { parseHealthieDate } from 'utils/helpers';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Typography } from 'components/ui/Typography/Typography';

import DeleteNote from './DeleteNote/DeleteNote';
import { ButtonContainer, NoteLabel, NotesContent, NotesHeader, StyledTextArea, TitleContainer } from './Note.styles';
import { useNote } from './useNote';

const MAX_LENGTH = 1000;

export const Note: React.FC = () => {
  const { noteId, goalId } = useParams();
  const isTodaysNote = isEmpty(noteId);
  const { t } = useTranslation('translation', { keyPrefix: 'pages.notes' });
  const navigate = useNavigate();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { note, loading, updateNoteEntry, deleteNoteEntry, isGoalCompleted } = useNote();
  const [noteContent, setNoteContent] = useState(note?.content ?? '');
  const isMaxLength = noteContent.length >= MAX_LENGTH;
  const charactersRemaining = Math.max(0, MAX_LENGTH - noteContent.length);
  const [deleteNote, setDeleteNote] = useState(false);

  useEffect(() => {
    if (note) {
      setNoteContent(note.content);
    }
  }, [note]);

  const lastUpdatedDate = useMemo(() => {
    if (!note?.updatedAt) return '';
    try {
      return format(parseHealthieDate(note.updatedAt), 'M/d/yyyy h:mmaaa');
    } catch (error) {
      /* intentionally empty */
    }
    return '';
  }, [note]);

  const createdAtDate = useMemo(() => {
    if (!note?.createdAt || isTodaysNote) return '';
    try {
      return format(parseHealthieDate(note.createdAt), 'EEE, MMM d');
    } catch (error) {
      /* intentionally empty */
    }
    return '';
  }, [note, isTodaysNote]);

  const handleNoteChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const noteContent = e.target.value;
    setNoteContent(noteContent);
    if (noteContent.length <= MAX_LENGTH && note) {
      await updateNoteEntry({ ...note, content: noteContent });
    }
  };

  const handleEditClick = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length);
    }
  };

  const handleDeleteNote = async () => {
    setDeleteNote(false);
    if (note?.id) {
      await deleteNoteEntry(note);
      if (isTodaysNote) {
        navigate(paths.homePage);
      } else {
        navigate(paths.allNotes.replace(':goalId', goalId ?? ''));
      }
    }
  };

  if (loading) {
    return <LoadingPage title={t('title')} />;
  } else if ((!isTodaysNote && !note) || !goalId) {
    navigate(paths.homePage);
  }

  if (deleteNote) {
    return <DeleteNote onKeep={() => setDeleteNote(false)} onDelete={handleDeleteNote} />;
  }

  return (
    <BaseLayout
      footer={null}
      headerProps={{
        title: isTodaysNote ? t('titleToday') : t('title'),
        variant: 'back',
        onBackClick: () => navigate(-1),
      }}
    >
      <NotesHeader
        progress={isGoalCompleted ? 100 : 0}
        icon={<Icon element={EditSquare} size={20} aria-hidden />}
        label={t('addNotesOptional')}
        description={lastUpdatedDate ? `${t('lastUpdated')}: ${lastUpdatedDate}` : ''}
      />
      <NotesContent>
        <TitleContainer>
          <Typography.H2>{isTodaysNote ? t('todaysNotes') : createdAtDate}</Typography.H2>
          <Icon
            element={EditPencil}
            size={20}
            color="blue.700"
            onClick={handleEditClick}
            style={{ cursor: 'pointer' }}
          />
        </TitleContainer>
        <StyledTextArea
          ref={textareaRef}
          value={noteContent}
          onChange={handleNoteChange}
          placeholder={t('placeholder')}
          maxLength={MAX_LENGTH}
          $isMaxLength={isMaxLength}
        />
        <NoteLabel $isMaxLength={isMaxLength}>
          {t('charactersRemaining', {
            remaining: charactersRemaining,
          })}
        </NoteLabel>
      </NotesContent>

      <ButtonContainer>
        <Button variant="secondary" size="md" onClick={() => setDeleteNote(true)}>
          {t('deleteNote.header')}
        </Button>
        <Button variant="primary" size="md" onClick={() => navigate(paths.allNotes.replace(':goalId', goalId ?? ''))}>
          {t('viewPreviousNotes')}
        </Button>
      </ButtonContainer>
    </BaseLayout>
  );
};

export default Note;
