import { NetworkStatus, useQuery } from '@apollo/client';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { GET_NOTES, GET_NOTES_COUNT } from 'api/notes/notes.query';
import { GET_CURRENT_USER_PROVIDERS } from 'api/user/user.query';
import isEmpty from 'lodash/isEmpty';
import { getTimeRange } from 'pages/progress/activity/timeUtils';
import { TimeInterval } from 'pages/progress/activity/types';
import { MonthOption, lastTwelveMonthsOptions } from 'pages/progress/medications/utils';
import { mapIndexToTabName, mapTabNameToIndex } from 'pages/progress/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { paths } from 'router/paths';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import { Spinner } from 'components/ui/Spinner/Spinner';
import TimeTabs, { TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitch, TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';
import { Typography } from 'components/ui/Typography/Typography';

import { NotesList } from '../NotesList/NotesList';
import { Note, PERSONAL_NOTES_CATEGORY } from '../useNote';
import * as Styled from './AllNotes.styles';
import NotesPdf from './NotesPdf';

type TimeRange = {
  startRange: string;
  endRange: string;
};

const timeRanges: Record<number, Record<TimeSwitchType, TimeInterval> | TimeInterval> = {
  [TimeTabsIndex.week]: {
    this: 'thisWeek',
    last: 'lastWeek',
  },
  [TimeTabsIndex.all_time]: 'allTime',
};

const getTimeInterval = (tab: number, subTab: TimeSwitchType, selectedMonth: MonthOption | null): TimeRange => {
  if (tab === TimeTabsIndex.week || tab === TimeTabsIndex.all_time) {
    const tabRange = timeRanges[tab];
    const timeInterval = typeof tabRange === 'string' ? tabRange : tabRange[subTab];
    return getTimeRange(timeInterval);
  } else if (tab === TimeTabsIndex.month) {
    if (selectedMonth) {
      return {
        startRange: selectedMonth.dateRange.split(':')[0],
        endRange: selectedMonth.dateRange.split(':')[1],
      };
    } else {
      return getTimeRange('thisMonth');
    }
  }
  return getTimeRange('thisWeek');
};

export const AllNotes: React.FC = () => {
  const { goalId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');

  const [currentTab, setCurrentTab] = useState(0);
  const [currentSubTab, setCurrentSubTab] = useState<TimeSwitchType>('this');
  const [selectedMonth, setSelectedMonth] = useState<MonthOption | null>(lastTwelveMonthsOptions[0]);
  const { t } = useTranslation('translation', { keyPrefix: 'pages.notes.allNotes' });
  const [timeInterval, setTimeInterval] = useState(getTimeRange('thisWeek'));
  const [notes, setNotes] = useState<Note[]>([]);

  const { data: userProviders } = useQuery(GET_CURRENT_USER_PROVIDERS);
  const coachPhoneNumber = useMemo(
    () =>
      userProviders?.currentUser?.providers?.find((provider) => provider?.qualifications?.toLowerCase() === 'coach')
        ?.phone_number,
    [userProviders],
  );

  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
  });

  const [pageSize] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  const {
    data,
    loading: notesLoading,
    fetchMore,
    networkStatus,
  } = useQuery(GET_NOTES, {
    variables: {
      start_range: timeInterval.startRange,
      end_range: timeInterval.endRange,
      offset: 0,
      page_size: pageSize,
      category: PERSONAL_NOTES_CATEGORY,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const { data: notesCountData } = useQuery(GET_NOTES_COUNT, {
    variables: {
      start_range: timeInterval.startRange,
      end_range: timeInterval.endRange,
      category: PERSONAL_NOTES_CATEGORY,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (inView && !notesLoading && data?.entries && hasMore) {
      fetchMore({
        variables: {
          offset: data.entries.length,
          limit: pageSize,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!prev.entries) return prev;

          if (!fetchMoreResult.entries || fetchMoreResult.entries.length === 0) {
            setHasMore(false);
            return prev;
          }

          if (fetchMoreResult.entries.length < pageSize) {
            setHasMore(false);
          }

          return {
            entries: [...prev.entries, ...fetchMoreResult.entries],
          };
        },
      });
    }
  }, [inView, notesLoading, data, fetchMore, pageSize, hasMore]);

  useEffect(() => {
    if (data?.entries) {
      setNotes(
        data.entries.map((entry) => ({
          id: entry.id,
          createdAt: entry.created_at,
          updatedAt: entry.updated_at,
          content: entry.content ?? '',
        })),
      );
    }
  }, [data]);

  useEffect(() => {
    const tabIndex = mapTabNameToIndex(tabParam);
    setCurrentTab(tabIndex);
  }, [tabParam]);

  useEffect(() => {
    setTimeInterval(getTimeInterval(currentTab, currentSubTab, selectedMonth));
    setHasMore(true);
  }, [currentTab, currentSubTab, selectedMonth]);

  useEffect(() => {
    setSelectedMonth(lastTwelveMonthsOptions[0]);
  }, [currentTab]);

  const onTabChange = (tab: number) => {
    setCurrentTab(tab);

    const tabName = mapIndexToTabName(tab);

    navigate(`${paths.allNotes.replace(':goalId', goalId ?? '')}?tab=${tabName}`, { replace: true });
  };

  const DownloadButton = (
    <PDFDownloadLink document={<NotesPdf notes={notes} phoneNumber={coachPhoneNumber} />} fileName="Notes.pdf">
      {(pdfData) => (
        <Button size="sm" disabled={pdfData.error !== null}>
          {t('download')}
        </Button>
      )}
    </PDFDownloadLink>
  );

  return (
    <BaseLayout
      footer={null}
      headerProps={{
        title: t('title'),
        variant: 'back',
        onBackClick: () => navigate(paths.homePage),
      }}
    >
      <Styled.TimeTabsContainer>
        <TimeTabs onTabChange={onTabChange} selectedTab={currentTab} />
      </Styled.TimeTabsContainer>
      {currentTab == TimeTabsIndex.all_time && (
        <Styled.AllTimeTabContainer>{DownloadButton}</Styled.AllTimeTabContainer>
      )}
      {currentTab !== TimeTabsIndex.all_time && currentTab !== TimeTabsIndex.month && (
        <Styled.WeekTabContainer>
          <Styled.TimeSwitchContainer>
            <TimeSwitch
              time={currentTab === TimeTabsIndex.week ? 'week' : 'month'}
              onSelect={(value: TimeSwitchType) => setCurrentSubTab(value)}
              selected={currentSubTab}
            />
          </Styled.TimeSwitchContainer>
          <Styled.WeekDownloadButton>{DownloadButton}</Styled.WeekDownloadButton>
        </Styled.WeekTabContainer>
      )}
      {currentTab === TimeTabsIndex.month && (
        <Styled.MonthTabContainer>
          <Styled.SelectContainer>
            <Select
              options={lastTwelveMonthsOptions}
              defaultValue={selectedMonth}
              onChange={(newValue) => {
                if (newValue) {
                  setSelectedMonth(newValue);
                }
              }}
              placeholder={t('Month')}
            />
          </Styled.SelectContainer>
          <Styled.MonthDownloadButton>{DownloadButton}</Styled.MonthDownloadButton>
        </Styled.MonthTabContainer>
      )}
      {isEmpty(notes) && !notesLoading && (
        <Styled.NoDataContainer>
          <Typography variant="Body1Bold">{t('noDataTitle')}</Typography>
          <Typography variant="Helper1">{t('noDataSubtitle')}</Typography>
        </Styled.NoDataContainer>
      )}
      <Styled.NotesContainer>
        {notesLoading && isEmpty(notes) && networkStatus !== NetworkStatus.fetchMore && (
          <Styled.NoDataContainer>
            <Spinner />
          </Styled.NoDataContainer>
        )}
        {notes.length > 0 && <NotesList notes={notes} notesCount={notesCountData?.entriesCount} goalId={goalId} />}
      </Styled.NotesContainer>
      {notes.length > 0 && hasMore && <Styled.LoadMoreContainer ref={loadMoreRef} />}
    </BaseLayout>
  );
};
