const fontSizes = {
  150: '130px',
  112: '112px',
  40: '40px',
  36: '36px',
  28: '28px',
  24: '24px',
  22: '22px',
  20: '20px',
  18: '18px',
  16: '16px',
  14: '14px',
  12: '12px',
  10: '10px',
} as const;

export const theme = {
  font: {
    family: {
      poppins: 'Poppins', // TODO add font family
    },
    weight: {
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
    },
    size: {
      ...fontSizes,
    },
    lineHeight: {
      48: '48px',
      36: '36px',
      32: '32px',
      28: '28px',
      24: '24px',
      20: '20px',
      12: '12px',
    },
  },
  icon: {
    size: {
      ...fontSizes,
      108: '108px',
      96: '96px',
      88: '88px',
      80: '80px',
      72: '72px',
      64: '64px',
      56: '56px',
      32: '32px',
      26: '26px',
    },
  },
  color: {
    black: {
      900: '#000000',
      800: '#1A1A1A',
      700: '#333333',
      600: '#4D4D4D',
      500: '#666666',
      400: '#808080',
      300: '#999999',
      200: '#B3B3B3',
      100: '#CCCCCC',
      50: '#E6E6E6',
    },
    blue: {
      900: '#0a172b',
      800: '#1a3b6b',
      700: '#245296',
      600: '#2e69c1',
      500: '#3375D6',
      400: '#5c91de',
      300: '#85ace6',
      200: '#adc8ef',
      100: '#d6e3f7',
      50: '#ebf1fb',
    },
    other: {
      white: '#FFFFFF',
      shell: '#CE2727',
      green: '#209D6A',
      greenLight: '#EAF9F3',
      greenDark: '#00864F',
      greenSea: '#7CD0AE',
      greenForest: '#196848',
      yellow: '#FFEB7B',
      red: '#CE2727',
      redLight: '#FCEAEA',
      progressRed: '#FC8787',
      linen: '#F8F7F2',
      linenLight: '#FDFCFA',
      yellowAlert: '#FFF0CA',
      modalBackground: '#212b3277',
      backgroundPhotoLightGrey: '#E6E7E9',
      backgroundPhotoDarkGrey: '#D1D3D3',
      violet: '#4F3FB2',
      backgroundBlue: '#1A4097',
    },
    transparent: 'transparent',
  },
  nav: {
    heightNumber: 70,
    height: '70px',
  },
  header: {
    height: '60px',
    back: {
      height: '48px',
    },
  },
} as const;
