import { addDays, endOfDay, endOfMonth, format, parseISO, startOfDay, startOfMonth, subDays } from 'date-fns';
import { Maybe } from 'utils/types';

import { TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';

import { TimeInterval, TimeSubTabs } from '../activity/types';
import { Answer } from './MedicationsReadings';

const defaultFormat = 'yyyy-MM-dd';

type TimeRange = {
  startRange: string;
  endRange: string;
};

export interface MonthOption {
  value: string;
  label: string;
  dateRange: string;
}

export const lastTwelveMonthsOptions: MonthOption[] = Array.from({ length: 12 }, (_, i) => {
  const today = new Date();
  const monthsAgo = new Date(today.getFullYear(), today.getMonth() - i);
  const startRange = format(startOfMonth(monthsAgo), defaultFormat);
  const endRange = format(endOfMonth(monthsAgo), defaultFormat);
  const label = format(monthsAgo, 'MMMM yyyy');
  const dateRange = `${startRange}:${endRange}`;
  return {
    value: dateRange,
    label,
    dateRange,
  };
});

//TODO Create separate timeUtils file -> MOT-426
export const sortByDateDesc = (goals: (Answer | undefined | null)[] = []): Answer[] => {
  const validGoals = goals.filter((goal): goal is Answer => !!goal) as Answer[];
  return validGoals.sort((a, b) => {
    const dateA = a.date ? parseISO(a.date) : null;
    const dateB = b.date ? parseISO(b.date) : null;
    if (!dateA && !dateB) {
      return 0;
    } else if (!dateA) {
      return 1;
    } else if (!dateB) {
      return -1;
    } else {
      return dateB.getTime() - dateA.getTime();
    }
  });
};

export const formatDate = (dateString: Maybe<string>, formatString = "iii MMM d 'at' h:mm a"): string => {
  if (!dateString) {
    return '';
  }
  const date = parseISO(dateString);
  return format(date, formatString);
};

const getDaysAgo = (days: number) => subDays(new Date(), days);

export const getAllTimeWithToday = (): TimeRange => {
  return {
    startRange: format(new Date(+0), defaultFormat),
    endRange: format(addDays(new Date(), 1), defaultFormat),
  };
};

export const getThisWeekWithTodayInterval = (): TimeRange => ({
  startRange: format(getDaysAgo(7), defaultFormat),
  endRange: format(addDays(new Date(), 1), defaultFormat),
});

export const getLastWeekInterval = (): TimeRange => {
  const startRange = format(getDaysAgo(14), defaultFormat);
  const endRange = format(getDaysAgo(7), defaultFormat);
  return { startRange, endRange };
};

export const getTimeRange = (range: TimeInterval): TimeRange | undefined => {
  switch (range) {
    case 'thisWeek':
      return getThisWeekWithTodayInterval();
    case 'lastWeek':
      return getLastWeekInterval();
    case 'allTime':
      return getAllTimeWithToday();
    default:
      return undefined;
  }
};

export const getIntervalForTab = (currentTab: number, currentSubTab: TimeSwitchType): TimeRange | undefined => {
  const timeRanges: Record<number, Record<TimeSubTabs, TimeInterval> | TimeInterval> = {
    [TimeTabsIndex.week]: {
      this: 'thisWeek',
      last: 'lastWeek',
    },
    [TimeTabsIndex.all_time]: 'allTime',
  };
  const currentTabRange = timeRanges[currentTab];
  if (!currentTabRange) return undefined;
  const currentTimeRange = typeof currentTabRange === 'string' ? currentTabRange : currentTabRange[currentSubTab];
  return getTimeRange(currentTimeRange);
};

export const filterGoalsByDateRange = (
  goals: Answer[],
  timeInterval?: { startRange: string; endRange: string },
): Answer[] => {
  if (!timeInterval) {
    return goals;
  }
  const startDate = startOfDay(new Date(timeInterval.startRange));
  const endDate = endOfDay(new Date(timeInterval.endRange));
  return goals.filter((goal) => {
    const date = parseISO(goal.date as string);
    const goalDate = new Date(date);
    return goalDate >= startDate && goalDate <= endDate;
  });
};
