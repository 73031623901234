import styled from 'styled-components';

export const TimeTabsContainer = styled.div`
  padding: 10px 16px;
`;

export const TimeSwitchContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SelectContainer = styled.div`
  flex-basis: 14rem;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 74px;
`;

export const NotesContainer = styled.div``;

export const MonthTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 16px;
  gap: 8px;
`;

export const WeekTabContainer = styled.div`
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  margin-top: 24px;
`;

export const AllTimeTabContainer = styled.div`
  display: flex;
  padding: 0 16px;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const WeekDownloadButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const MonthDownloadButton = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
`;
