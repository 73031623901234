import styled from 'styled-components';

export const CircuralProgressWrapper = styled.div`
  position: relative;
  max-width: 100%;
`;

export const CircuralProgress = styled.svg`
  transform: rotate(-90deg);
  display: block;
`;

export const CompletedPercentageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.color.black[200]};
  border-radius: 50%;
  justify-self: center;
`;
