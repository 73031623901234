import { gql } from '__generated__';

export const GET_NOTES = gql(`
  query getNotes($user_id: String, $start_range: String, $end_range: String, $page_size: Int, $offset: Int, $category: String, $order_by: EntryOrderKeys ) {
    entries(client_id: $user_id, start_range: $start_range, end_range: $end_range, type: "NoteEntry", summary_view: false, page_size: $page_size, offset: $offset, category: $category, order_by: $order_by) {
        id
        created_at
        updated_at
        type
        content: reflection
        category
    }
  }
`);

export const GET_NOTE = gql(`
  query getNote($user_id: ID, $id: ID) {
    entry(client_id: $user_id, id: $id, type: "NoteEntry") {
      id
      created_at
      updated_at
      type
      content: reflection
      category
    }
  }
`);

export const GET_NOTES_COUNT = gql(`
  query getNotesCount($user_id: String, $start_range: String!, $end_range: String!, $category: String) {
    entriesCount(client_id: $user_id, start_range: $start_range, end_range: $end_range, type: "NoteEntry", category: $category)
  }
`);
