import { Goal } from '__generated__/graphql';
import isEmpty from 'lodash/isEmpty';
import { getTimeRange } from 'pages/progress/activity/timeUtils';
import { useMemo } from 'react';
import { Maybe } from 'utils/types';

import { TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';

type Statistics = {
  requiredMeasurementsInWeek: number;
  completedMeasurementsInWeek: number;
  goal: Maybe<string>;
};

export const useGetMeasurementsStatistics = (goals: Goal[], weekSwitch?: TimeSwitchType): Statistics => {
  const filteredGoals = goals?.filter((goal) => /pressure/i.test(goal?.name?.toLowerCase() || '')) || [];

  // Sort the filtered goals by the start_on date, newest first
  filteredGoals.sort((a, b) => {
    const dateA: Date = new Date(a.start_on);
    const dateB: Date = new Date(b.start_on);
    return dateB > dateA ? 1 : dateB < dateA ? -1 : 0;
  });

  const pressureGoal = !isEmpty(filteredGoals) ? filteredGoals[0] : null;

  const requiredMeasurementsInWeek = useMemo(() => {
    return (pressureGoal?.subgoals?.length || 0) * 7;
  }, [pressureGoal]);

  const weekInterval = useMemo(() => {
    if (weekSwitch) {
      return getTimeRange(`${weekSwitch}Week`);
    }
    return getTimeRange('thisWeek');
  }, [weekSwitch]);

  const completedMeasurementsInWeek =
    pressureGoal?.subgoals?.reduce((acc, item) => {
      const filteredHistories = item?.goal_histories?.filter((history) => {
        return weekInterval.startRange <= history.completed_on && history.completed_on <= weekInterval.endRange;
      });
      return acc + (filteredHistories.length || 0);
    }, 0) || 0;

  return {
    requiredMeasurementsInWeek,
    completedMeasurementsInWeek,
    goal: pressureGoal?.description,
  };
};
