import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Box = styled.div`
  padding: 0 16px;
  background-color: ${({ theme }) => theme.color.other.white};
  border: 1px solid ${({ theme }) => theme.color.black[50]};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
`;

export const EntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DateContainer = styled.div`
  margin: 8px 0;
`;

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NoteContainer = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 80%;
`;

export const NoteContent = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NotesCount = styled(Typography.Div)`
  margin-top: 18px;
  margin-bottom: 8px;
`;
