import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { theme } from 'assets/styles/theme';
import { format } from 'date-fns';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { parseHealthieDate } from 'utils/helpers';

import Pdf from 'components/pdf';
import PdfPage from 'components/pdf/page';

import { Note } from '../useNote';

type Props = {
  notes: Note[];
  phoneNumber?: string | null;
};

const NotesPdf: React.FC<Props> = ({ notes, phoneNumber }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.notes.pdf' });

  const sortedGroupedNotes = useMemo(() => {
    const entriesWithDates = notes.filter((note) => !isEmpty(note.createdAt) && !isEmpty(note.content));
    const groupedData = groupBy(entriesWithDates, (el) => format(parseHealthieDate(el.createdAt), 'yyyy-MM-dd'));

    return sortBy(groupedData, (group) => {
      return entriesWithDates.indexOf(group[0]);
    });
  }, [notes]);

  const styles = StyleSheet.create({
    viewStyles: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 18,
      paddingTop: 16,
    },
    noteContainer: {
      display: 'flex',
      gap: 8,
      marginLeft: 16,
      marginRight: 16,
    },
    dateHeader: {
      fontSize: 11,
      fontWeight: 'bold',
      paddingLeft: 2,
      paddingBottom: 4,
    },
    noteItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    noteItem: {
      display: 'flex',
      backgroundColor: theme.color.other.white,
      border: `1px solid ${theme.color.black[100]}`,
      borderRadius: 8,
      padding: '4px 6px',
      gap: 4,
    },
    noteTime: {
      fontSize: 6,
      color: theme.color.black[800],
    },
    noteText: {
      fontSize: 10,
    },
  });
  return (
    <Pdf>
      <PdfPage coachNumber={phoneNumber}>
        <View style={styles.viewStyles}>
          <Text>{t('notes')}</Text>
        </View>
        <View style={styles.noteContainer}>
          {sortedGroupedNotes.map((group) => (
            <View key={group[0].createdAt}>
              <Text style={styles.dateHeader}>{format(parseHealthieDate(group[0].createdAt), 'EEE, MMM d, yyyy')}</Text>
              <View style={styles.noteItemContainer}>
                {group.map((note) => (
                  <View key={note.id} style={styles.noteItem}>
                    <Text style={styles.noteTime}>
                      {t('lastUpdated', { time: format(parseHealthieDate(note.updatedAt), 'h:mm a') })}
                    </Text>
                    <Text style={styles.noteText}>{note.content}</Text>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
      </PdfPage>
    </Pdf>
  );
};

export default NotesPdf;
